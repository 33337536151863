/* eslint-disable camelcase */
import {useMemo} from 'react';
import dayjs from 'dayjs';

const useCandidateSubscriberDataDefaultValues = (candidate = {}) => {
  const {subscriber_data} = candidate || {};

  return useMemo(() => {
    if (!candidate || !Object.keys(candidate).length) {
      return {};
    }

    const doNotApproachUntil =
      subscriber_data && subscriber_data.do_not_approach_until ? dayjs(subscriber_data.do_not_approach_until) : dayjs(null);

    const urls = candidate?.insights?.find(x => x.type === 'urls');
    const linkedin_blurb = candidate?.insights?.find(x => x.type === 'linkedin_blurb');
    const website_blurb = candidate?.insights?.find(x => x.type === 'website_blurb');

    return {
      contact_details: subscriber_data && subscriber_data.contact_details ? subscriber_data.contact_details : null,
      do_not_approach_reason: subscriber_data && subscriber_data.do_not_approach_reason,
      do_not_approach_until: doNotApproachUntil.isValid() ? doNotApproachUntil : null,
      urls: urls?.note ?? '',
      linkedin_blurb: linkedin_blurb?.note ?? '',
      website_blurb: website_blurb?.note ?? '',
    };
  }, [candidate, subscriber_data]);
};

export default useCandidateSubscriberDataDefaultValues;
