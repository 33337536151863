/* eslint-disable no-case-declarations */
/* eslint-disable fp/no-mutation */
/* eslint-disable no-plusplus */
import {combineReducers} from 'redux';

const sectors = (state = [], action) => {
  switch (action.type) {
    case 'SET_SECTORS':
      const baseState = {...state};
      for (const sector of action.sectors) {
        baseState[sector.id] = sector;
      }
      return baseState;
    case 'REMOVE_SECTORS':
      return action.ids.reduce((nextState, id) => {
        // eslint-disable-next-line no-unused-vars
        const {[id]: remove, ...nextSectors} = nextState;
        return nextSectors;
      }, state);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_SECTORS_LOADING':
      return true;
    case 'SET_SECTORS':
    case 'SET_SECTORS_ERROR':
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_SECTORS':
      return Date.now();
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case 'SET_SECTORS_ERROR':
      return action.message;
    case 'SET_SECTORS_LOADING':
    case 'SET_SECTORS':
      return false;
    default:
      return state;
  }
};

export default combineReducers({sectors, loading, loaded, error});
